import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"

import { sendEmailToLoginWithEmailLink } from "https://framer.com/m/auth-IHGH.js"

import React from "react"
import { toast, Toaster } from "react-hot-toast"

const emailSchema = z.object({
    email: z
        .string()
        .nonempty("O campo de e-mail é obrigatório")
        .email("Por favor, insira um e-mail válido"),
})

const useStore = createStore({
    value: "",
    error: null,
    success: false,
    disabled: true,
    step: 0,
})

const BASE_URL = "https://curso.framer.website"

export function onError(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        if (store.error) {
            return (
                <Component
                    {...props}
                    title={store.error[0].message}
                    style={{ opacity: store.error ? 1 : 0 }}
                />
            )
        }
    }
}

export function onButton(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                disabled={store.disabled}
                variant={store.disabled ? "Disabled" : "Primary"}
            />
        )
    }
}

export function onInput(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        const handleInput = (value) => {
            try {
                const validatedForm = emailSchema.parse({
                    email: value,
                })
                setStore({
                    ...store,
                    value,
                    disabled: false,
                })
            } catch (err) {
                setStore({
                    ...store,
                    disabled: true,
                })
            }
        }
        return <Component {...props} onValueChange={handleInput} />
    }
}

export function onForm(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        const [isSubmit, setSubmit] = React.useState(false)

        const handleSubmit = async (e) => {
            e.preventDefault()

            if (isSubmit) return

            try {
                setSubmit(true)

                await sendEmailToLoginWithEmailLink(store.value)

                window.location.href = BASE_URL + "/login/enviado"

                setStore({
                    ...store,
                    success: true,
                })
            } catch (err) {
                setSubmit(false)
                setStore({
                    ...store,
                    error: err.issues,
                })

                if (err.name && err.name === "FirebaseError") {
                    toast.error("Erro ao autenticar, tente novamente!")
                }
                setSubmit(false)
            }
        }

        return (
            <form onSubmit={handleSubmit} {...props}>
                <Component {...props} />
            </form>
        )
    }
}
